import { defineStore } from 'pinia'
import qs from 'qs'

export const useUrlStore = defineStore({
  id: 'urlStore',
  state: () => ({
    selected: {
      language: 'en',
      website: undefined,
      segment: undefined,
      category: undefined,
      collection: undefined,
      search: undefined as string | undefined,
      products: new Map(),
    },
    options: {
      languages: [],
      websites: [],
      segments: [],
      categories: [],
      collections: [],
      products: [],
    } as SelectOptions,
    exceptionProducts: {
      collection: ['carpettile', 'broadloom', 'outdoorcarpet', 'sheetvinyl'],
      technical: ['broadloom', 'outdoorcarpet'],
    },
    suggestions: [] as string[],
    loading: false,
  }),
  actions: {
    async getOptions() {
      try {
        this.loading = true
        this.selected.products.clear()
        const result = await $fetch('/api/options', {
          method: 'POST',
          body: JSON.stringify({
            language: this.selected.language,
            website: this.selected.website,
            segment: this.selected.segment,
            category: this.selected.category,
            collection: this.selected.collection,
            search: this.selected.search ? this.selected.search : null,
          }),
        })
        if (!result || typeof result !== 'object')
          return

        this.$patch({
          options: result,
        })
      }
      catch (e) {
        console.error(e)
      }
      finally {
        this.loading = false
      }
    },
    async getSuggestions() {
      if (this.selected.search !== null && this.selected.search && this.selected.search.length > 2) {
        const result = await $fetch('/api/suggest', {
          method: 'GET',
          query: {
            language: this.selected.language,
            website: this.selected.website,
            segment: this.selected.segment,
            q: this.selected.search,
          },
        }).catch((e) => {
          console.error(e)
        })
        if (!result || typeof result !== 'object')
          return

        this.$patch({
          suggestions: result,
        })
      }
    },
    addProduct(product: any) {
      this.selected.products.set(`${product.code}-${product.segment}-${product.collection}`, product)
    },
    removeProduct(product: any) {
      this.selected.products.delete(`${product.code}-${product.segment}-${product.collection}`)
    },
    addAllProducts(products: any[]) {
      this.selected.products.clear()
      products.forEach((product) => {
        if (!product.id.includes('-all'))
          this.selected.products.set(product.id, product)
      })
    },
  },
  getters: {
    hideAllButton(store) {
      if (store.options.categories.length > 0)
        return !store.options.categories.find(category => ['broadloom', 'carpettile', 'outdoorcarpet', 'sheetvinyl'].includes(category))
      if (store.selected.category)
        return !['broadloom', 'carpettile', 'outdoorcarpet', 'sheetvinyl'].includes(store.selected.category)
    },
    hasProduct(store) {
      return (product: any) => store.selected.products.has(`${product.code}-${product.segment}-${product.collection}`)
    },
    basePdfUrl() {
      const baseURL = useRuntimeConfig().public.websiteBaseUrl
      return {
        single: `${baseURL}/api/pdf/bulletins`,
        sells: `${baseURL}/api/pdf/bulletins/sells`,
        technical: `${baseURL}/api/pdf/bulletins/technical`,
        collection: `${baseURL}/api/pdf/bulletins/collection`,
      }
    },
    exceptions(store) {
      let collection = true
      let technical = true
      store.selected.products.forEach((product) => {
        if (store.exceptionProducts.collection.includes(product.category)) {
          collection = false
          return false
        }
        if (store.exceptionProducts.technical.includes(product.category)) {
          technical = false
          return false
        }
      })
      return {
        collection,
        technical,
      }
    },
    productUrlSearchParams(store) {
      const products: object[] = []
      store.selected.products.forEach((product) => {
        products.push({
          code: product.code,
          segment: product.segment.filter((seg: string) => seg !== 'all')[0].replace(/\s/g, '-'),
          website: product.website,
          productType: product.productType,
          category: product.category,
          language: product.language,
        })
      })
      return qs.stringify(products, { encodeValuesOnly: true, arrayFormat: 'comma' })
    },
  },
})
